<template>
    <div>
        <div class="toolBar mBottom10 clearfix">
            <el-cascader v-show="global.isAdmin" :props="global.orgTreeProps" :options="global.orgList" :show-all-levels="false" v-model="global.orgId" @change="handleChange" size='small' style="margin-right: 20px"></el-cascader>
            <el-button  size="small" class="fr" @click="refresh">刷新</el-button>
        </div>
        <el-card>
            <el-table height="502" size="small" :data="tableData" class="w100" :height="tableHeight"  highlight-current-row>
                <el-table-column type="index" width="50" align="center"></el-table-column>
                <el-table-column  prop="orgName" label="机构名称" align="center"></el-table-column>
                <el-table-column  prop="appName" label="公众号名称" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type='text'
                                   @click="openRelateDialog(scope.row)">关联公众号
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="toolBar clearfix" style="margin-top: 10px">
                <pagi-nation :page='page' @sizeChange='sizeChange' @currentChange='currentChange'></pagi-nation>
            </div>
        </el-card>

        <el-dialog v-dialogDrag :visible.sync="relateDialog" width="30%" title='关联公众号'>
            <el-form ref='relateItem' :model='relateItem' size='small'> 
                <el-row>
                    <el-col :sm="24">
                        <el-form-item prop="remark">
                            <el-select size="small" v-model="relateItem.appId" clearable placeholder="请选择" class="w100">
                                <el-option
                                    v-for="item in appList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="relateDialog = false" size='small'>取消</el-button>
                <el-button @click="relateConfirm" type="primary" size='mini'>关联</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    class relateForm {
        constructor() {
            this.orgId = "";
            this.appId = "";
        }
        reset() {
            this.constructor();
        }
    }
    export default {
        name: "orgTencent",
        data(){
            return{
                page: new this.Page(),
                relateDialog: false,
                tableData:[],
                appList: [],
                relateItem:new relateForm(),
                tableHeight: document.documentElement.clientHeight - 288,
            }
        },
        computed:{

        },
        created() {
            this.load();
            this.getAppList();
        },
        methods:{
            refresh() {
                this.getAppList();
                this.load();
            },
            sizeChange(val) {
                this.page.pageSize = val;
                this.page.currentPage = 1;
                // do sth
                this.load()
            },
            currentChange(val) {
                this.page.currentPage = val;
                // do sth
                this.load()
            },
            handleChange(val) {
                if (val) {
                    localStorage.setItem('orgId',JSON.stringify(val));
                    this.load();
                }
            },
            async getAppList() {
                let res = await this.$http.post('/mp/getWxMpCombo');
                if (res.state) {
                    this.appList = res.data;
                }
            },
            async load() {
                let formData = {}
                formData.orgId = this.deepClone(this.global.orgId)
                if (formData.orgId && formData.orgId.length) {
                    formData.orgId = formData.orgId.pop()
                }
                formData.limit = this.page.pageSize
                formData.offset = (this.page.currentPage - 1) * this.page.pageSize
                let res = await this.$http.post('/mp/getWxMpOrg', formData);
                if (res.state) {
                    if (res.data) {
                        this.tableData = res.data.list;
                        this.page.total = res.data.totalCount
                    }
                }
            },
            openRelateDialog(row) {
                this.relateItem.orgId = row.orgId;
                this.relateDialog = true
            },
            relateConfirm() {
                this.$confirm('确认关联公众号?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.relate();
                }).catch(() => {

                });
            },
            async relate() {
                let res = await this.$http.post('/mp/updWxMpOrg', this.relateItem);
                if (res.state) {
                    this.$message.success('操作成功');
                    this.load();
                }
                this.relateDialog = false;
            },
        },
        mounted() {
            window.onresize = () => {
                window.fullHeight = document.documentElement.clientHeight;
                this.tableHeight = window.fullHeight - 288;
            }
        }
    };
</script>

<style scoped>

</style>